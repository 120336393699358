import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import PropTypes from 'prop-types'

import breakpoint from '@utils/breakpoint'
import Copy from '@objects/copy'
import Headline from '@objects/headline'
import TracklineWrapper from '@objects/tracklineWrapper'
import Button from '@objects/button'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import Carousel from '@objects/carousel'
import { useTheme } from '@hooks'
import clsx from 'clsx'

const Item = styled(Link)`
  display: flex;
  align-items: stretch;
  &:hover {
    text-decoration: none;
    color: initial;
  }
  &:focus {
    outline: 1px dotted #000;
  }
`

const Swiper = styled(Carousel)`
  .swiper-slide {
    &.swiper-slide-active {
      .gradient-overlay {
        display: none;
      }
    }
    &-prev,
    &-duplicate-prev,
    &.swiper-slide-prev {
      .gradient-overlay {
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 1) 50%,
          rgba(255, 255, 255, 0.55) 100%
        );
      }
    }
    &.swiper-slide-next {
      .gradient-overlay {
        right: 0;
        background: linear-gradient(
          to left,
          rgba(255, 255, 255, 1) 50%,
          rgba(255, 255, 255, 0.55) 100%
        );
      }
    }
    &:hover {
      .arrow-right {
        opacity: 1;
      }
    }
  }
  .button {
    top: 40%;
    ${tw`absolute z-50 hidden rounded-full bg-blue items-center justify-center text-white`}
    &.prev {
      left: ${({ theme }) => theme.spacing[1]};
    }
    &.next {
      right: ${({ theme }) => theme.spacing[1]};
    }
    &:hover {
      ${tw`bg-gray-light text-black`}
    }
    ${breakpoint('md')`
      ${tw`flex`}`}
  }
`

const Gradient = styled.div.attrs({
  className: 'h-full absolute top-0 z-50',
})`
  transform: scale(1.1);
  width: 97%;
  cursor: pointer;
`

const Trackline = styled(TracklineWrapper).attrs({
  className: 'mx-auto',
})`
  width: 284px;
  ${breakpoint('sm')`
      width: 612px;
    `}
`

function FactCarousel({ className, items, headline, button }) {
  const [currentSlide, setCurrentSlide] = useState(0)
  const { screens } = useTheme()
  const [isMobile, setIsMobile] = useState(false)

  // const slideAction = (idx) => {
  //   setCurrentSlide(idx)
  // }

  // const sliderSettings = {
  //   loadOnTransitionStart: false,
  //   preloadImages: false,
  //   loop: true,
  //   centeredSlides: true,
  //   slidesOffsetBefore: 0,
  //   slidesOffsetAfter: 0,
  //   spaceBetween: 0,
  //   slidesPerView: 'auto',
  //   paginationClickable: true,
  //   pagination: {
  //     el: '.pagination',
  //     clickable: true,
  //   },
  //   breakpoints: {
  //     768: {
  //       spaceBetween: -15,
  //     },
  //     1024: {
  //       spaceBetween: -15,
  //     },
  //     1200: {
  //       spaceBetween: 0,
  //     },
  //   },
  // }

  useEffect(() => {
    setIsMobile(window.innerWidth < parseInt(screens.md, 9))
  }, [screens])

  function renderItems() {
    return items.map((item, i) => (
      <Item key={`${item.created}${i}`} to={button.to} className={clsx('my-9')}>
        <Trackline
          rounded
          gapLarge={isMobile}
          thick={!isMobile}
          gap={currentSlide === i ? 'topright' : 'bottomleft'}
          secondcircle={currentSlide === i ? 'fullhori' : 'fullvert'}
          firstcircle={currentSlide === i ? 'halfvert' : 't'}
        >
          <div className="mx-auto my-0 sm:p-8 p-7 flex flex-col-reverse sm:flex-row items-center justify-between">
            <div className="sm:w-4/7 w-full sm:pr-4">
              <div className={'overflow-hidden block'}>
                <Headline level={isMobile ? 3 : 2} ariaLabel={item.headline}>
                  {item.headline}
                </Headline>
              </div>
              <Copy className="sm:text-lg text-base text-black-650 pt-3 leading-4">
                {item.copy}
                <Button
                  className={clsx(
                    'arrow-right transition duration-150',
                    currentSlide === i ? 'opcaity-100' : 'opacity-0'
                  )}
                  textlink
                />
              </Copy>
            </div>
            {item.image && (
              <div className="sm:w-3/7 w-full">
                <Img
                  alt={item.image.description}
                  fluid={{ ...item.image.fluid, media: `(min-width: 0px)` }}
                  fadeIn={false}
                  backgroundColor={true}
                  loading={'eager'}
                />
              </div>
            )}
          </div>
        </Trackline>
        <Gradient className="gradient-overlay" />
      </Item>
    ))
  }

  return (
    <div className="relative md:py-1">
      <Headline className="text-center" ariaLabel={headline}>
        {headline}
      </Headline>
      {items.length > 1 ? (
        <Swiper slidesPerView={'auto'} centeredSlides={true} loop={false}>
          {renderItems()}
        </Swiper>
      ) : (
        renderItems()
      )}
      {button && (
        <div className="text-center mt-9">
          <Button className={'btn-large'} to={button.to}>
            {button.label}
          </Button>
        </div>
      )}
    </div>
  )
}

FactCarousel.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.string,
  button: PropTypes.object,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      headline: PropTypes.string,
      copy: PropTypes.string,
      image: PropTypes.object,
    })
  ).isRequired,
}

export default FactCarousel
