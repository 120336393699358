import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledTab = styled.button`
  &:focus {
    outline: 1px dotted #000;
  }
`

const Content = styled.span`
  margin-top: 6px;
`

function TabHead({ children, onClick, selfindex, currentTab }) {
  const TabRef = useRef(null)
  const [activeTab, setActiveTab] = useState(currentTab || 0)

  useEffect(() => {
    if (currentTab !== activeTab) {
      setActiveTab(currentTab)
      if (currentTab === selfindex) {
        TabRef.current.focus()
      }
    }
  }, [currentTab])

  function getClasses() {
    if (selfindex === currentTab) {
      return 'bg-red text-white'
    }
  }

  function getIdenticatorBorderClasses() {
    if (selfindex === currentTab) {
      return 'w-3 h-3 transform scale-125 border-white'
    }
    return 'w-3 h-3 border-black'
  }

  function getIdenticatorClasses() {
    if (selfindex === currentTab) {
      return 'w-2 h-2 transform scale-75 bg-white origin-center'
    }
    return 'bg-transparent'
  }

  return (
    <StyledTab
      className={`inline-flex flex-1 items-center justify-center text-center rounded-full cursor-pointer transition duration-150 py-2 ${getClasses()}`}
      onClick={onClick}
      role="tab"
      aria-selected={selfindex === currentTab}
      aria-controls={`panel-${selfindex}`}
      tabIndex={selfindex === currentTab ? null : -1}
      ref={TabRef}
    >
      <span
        className={`hidden lg:flex items-center justify-center border rounded-full mr-2 transition duration-150 ${getIdenticatorBorderClasses()}`}
      >
        <span
          className={`rounded-full transition duration-300 ${getIdenticatorClasses()}`}
        />
      </span>
      <Content className="inline-flex font-normal text-lg lg:text-xl leading-none">
        {children}
      </Content>
    </StyledTab>
  )
}

TabHead.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  type: PropTypes.string,
  selfindex: PropTypes.number,
  currentTab: PropTypes.number,
}

export default TabHead
