import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import useDateFns from '@hooks/useDateFns'
import { get } from 'lodash'

import { useIntl } from 'react-intl'
import useStaticModule from '@hooks/useStaticModule'

import Container from '@objects/container'
import Stage from '@components/stage'
import NewsroomTeaser from '@components/newsroomTeaser'

import CategoryTabs from '@components/categoryTabs'
import FactCarousel from '@components/factCarousel'
import BigFiveBlogCard from '@components/bigFiveBlogCard/index'

function RootIndex({ data, pageContext }) {
  const intl = useIntl()
  const { differenceInDays } = useDateFns()
  const {
    getStaticFieldMedia,
    getStaticFieldLink,
    getStaticInfoCard,
    getAllStaticInfoCards,
    getAllStaticFacts,
  } = useStaticModule(pageContext.modules)

  const stageModule = {
    image: getStaticFieldMedia('home.stage', 'home.stage.image'),
    video: getStaticFieldMedia('home.stage', 'home.stage.video'),
    videoPoster: getStaticFieldMedia('home.stage', 'home.stage.video.poster'),
    infocard: getStaticInfoCard('home.stage', 'home.stage.infocard'),
    disruptor: {
      ...getStaticFieldMedia('home.stage', 'home.stage.disruptor'),
      ...getStaticFieldLink('home.stage', 'home.stage.disruptor'),
    },
  }

  const factsCarousel = {
    items: getAllStaticFacts('home.fact.carousel')?.splice(0, 6),
    headline: intl.formatMessage({ id: 'carousel.facts.headline' }),
    button: {
      label: intl.formatMessage({ id: 'button.facts' }),
      to: '/fakten/',
    },
  }

  const blogList = {
    headline: intl.formatMessage({ id: 'home.blog.headline' }),
    button: {
      label: intl.formatMessage({ id: 'button.blog' }),
      to: '/blog/',
    },
    cards: (() => {
      // get first 3 articles in DESC order
      const blogData = get(data, 'blogArticles')
      return [blogData.articleHero, ...(blogData.articles || [])]
        .filter((entry) => !!entry)
        .sort((a, b) => differenceInDays(b.createdAt, a.createdAt))
        .slice(0, 5)
        .map((card) => ({
          image: card.image,
          stageImage: !!card.stageImage ? card.stageImage : null,
          headline: card.title,
          copy: card.teaserCopy?.childMarkdownRemark?.html,
          tag: card.category?.title,
          date: card.createdAt,
          link: card.slug,
        }))
    })(),
  }

  const newsevents = {
    headline: intl.formatMessage({ id: 'home.newsevents.headline' }),
    subheadline: intl.formatMessage({ id: 'home.newsevents.subheadline' }),
    button: {
      label: intl.formatMessage({ id: 'button.newsroom' }),
      to: intl.formatMessage({ id: 'path.newsroom' }),
    },
    background: getStaticFieldMedia('home.newsevents', 'home.newsevents.image'),
    items: (() => {
      const articles = get(data, 'newsroomArticles')?.articles || []
      const events = get(data, 'contentfulEventList')?.events || []

      return []
        .concat(articles, events)
        .sort((a, b) => differenceInDays(a.createdAt, b.createdAt))
        .slice(0, 3)
        .map((item) => ({
          id: item.id,
          headline: item.title,
          copy:
            item.teaserCopy?.childMarkdownRemark?.html ||
            item.copy?.childMarkdownRemark?.html,
          tag: {
            name:
              item.__typename === 'ContentfulArticle'
                ? intl.formatMessage({ id: 'tag.news' })
                : intl.formatMessage({ id: 'tag.events' }),
            to:
              item.__typename === 'ContentfulArticle'
                ? intl.formatMessage({ id: 'path.newsroom' })
                : intl.formatMessage({ id: 'path.events' }),
          },
          date: item.dateTimeStart || item.createdAt,
          href: `${
            item.__typename === 'ContentfulArticle'
              ? intl.formatMessage({ id: 'path.newsroom' })
              : intl.formatMessage({ id: 'path.events' })
          }${item.slug}`,
        }))
    })(),
  }

  const categoryTabs = {
    items: getAllStaticInfoCards('home.categorytabs'),
  }

  return (
    <>
      <Stage
        type="infocard"
        image={stageModule.image}
        video={stageModule.video}
        videoPoster={stageModule.videoPoster}
        infocard={stageModule.infocard}
        disruptor={stageModule.disruptor}
      />

      <BigFiveBlogCard
        headline={blogList.headline}
        button={blogList.button}
        cards={blogList.cards}
        options={{ primary: true }}
      />

      {factsCarousel.items?.length > 2 && (
        <Container
          className="pt-7 pb-7 md:mt-13 md:pb-0 md:pt-0"
          nopadding
          width="lg"
          role="region"
          aria-label="FactsCarousel"
        >
          <FactCarousel
            items={factsCarousel.items}
            headline={factsCarousel.headline}
            button={factsCarousel.button}
          />
        </Container>
      )}

      {categoryTabs.items && <CategoryTabs items={categoryTabs.items} />}

      <NewsroomTeaser
        headline={newsevents.headline}
        subheadline={newsevents.subheadline}
        button={newsevents.button}
        items={newsevents.items}
        background={newsevents.background}
        options={{ primary: true }}
      />
    </>
  )
}

RootIndex.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default RootIndex

export const pageQuery = graphql`
  query HomePageQuery($locale: String!) {
    blogArticles: contentfulArticleList(
      node_locale: { eq: $locale }
      identifier: { eq: "blog" }
    ) {
      articleHero {
        slug
        title
        teaserCopy {
          childMarkdownRemark {
            html
          }
        }
        createdAt
        image {
          fluid(quality: 80, maxHeight: 428) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          description
        }
        stageImage {
          fluid(quality: 80, maxHeight: 428) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          description
        }
        category {
          title
        }
      }
      articles {
        slug
        title
        teaserCopy {
          childMarkdownRemark {
            html
          }
        }
        createdAt
        image {
          fluid(quality: 80, maxHeight: 428) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          description
        }
        category {
          title
        }
      }
    }
    newsroomArticles: contentfulArticleList(
      node_locale: { eq: $locale }
      identifier: { eq: "newsroom" }
    ) {
      articles {
        id
        __typename
        title
        slug
        teaserCopy {
          childMarkdownRemark {
            html
          }
        }
        createdAt
      }
    }
    contentfulEventList(
      node_locale: { eq: $locale }
      identifier: { eq: "events" }
    ) {
      events {
        id
        __typename
        title
        slug
        dateTimeStart
        createdAt
        copy {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`
